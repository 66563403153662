import { useTranslation } from 'next-i18next';
import React from 'react';

import classNames from 'classnames';
import Image from 'next/image';
import PropTypes from 'prop-types';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { trackContactLead } from '../../utils/dataLayer';
import ButtonPrimary from '../ButtonPrimary';
import ButtonSecondary from '../ButtonSecondary';
import PhotoCred from '../PhotoCred';
import s from './OfferIntro.module.scss';

const OfferIntro = ({
  titleTop,
  titleBottom,
  text,
  image,
  richtext,
  lastUpdated,
  share,
}) => {
  const [ref, isVisible] = useIntersectionObserver();
  const [imageRef, imageIsVisible] = useIntersectionObserver();
  const imageGridClasses = classNames('grid', {
    [s.ImageGrid]: true,
    [s.ImageVisible]: imageIsVisible,
  });

  const titleContainerClasses = classNames(s.TitleContainer, {
    [s.RichText]: richtext,
  });

  const textClasses = classNames(s.Text, { [s.RichText]: richtext });
  const classes = classNames(s.Root, {
    [s.Visible]: isVisible,
    [s.IsFromRichText]: richtext,
  });

  const buttonContainerClasses = classNames(s.ButtonContainer, {
    [s.ButtonContainerRichText]: richtext,
  });
  const imageStyle = {
    objectFit: 'cover',
  };

  if (!!image && !!image.focal && !!image.focal.x && !!image.focal.y) {
    imageStyle.objectPosition = `${image.focal.x} ${image.focal.y}`;
  }

  const { t } = useTranslation();
  return (
    <section className={classes} ref={ref}>
      <div ref={imageRef} className={imageGridClasses}>
        {!!image?.url && (
          <div className={`${s.ImageContainer}`}>
            <Image
              className={s.Image}
              src={image.url}
              alt=""
              fill
              style={imageStyle}
            />
            <PhotoCred credit={image?.credit} />
          </div>
        )}
      </div>

      <div className={`${s.Content} grid`}>
        {!richtext ? (
          <>
            <h2 className={`${s.Title} ${s.TitleDesktop} grid`}>
              <span className={s.TitleTop}>{titleTop}</span>
              <span className={s.TitleBottom}>{titleBottom}</span>
            </h2>
            <h2 className={`${s.Title} ${s.TitleMobile} grid`}>
              <span className={s.TitleContainer}>
                <span
                  className={s.TitleTop}
                  dangerouslySetInnerHTML={{ __html: titleTop }}
                />{' '}
                <span
                  className={s.TitleBottom}
                  dangerouslySetInnerHTML={{ __html: titleBottom }}
                />
              </span>
            </h2>
          </>
        ) : (
          <>
            <h1 className={`${s.Title} ${s.TitleMobile} grid`}>
              <span className={titleContainerClasses}>
                <span className={s.TitleTop}>
                  {titleTop}
                  <span className={s.TitleBottom}> {titleBottom}</span>
                </span>
              </span>
            </h1>

            <h1 className={`${s.Title} ${s.TitleDesktop} grid`}>
              <span className={titleContainerClasses}>
                <span className={s.TitleTop}>
                  {titleTop}
                  <span className={s.TitleBottom}> {titleBottom}</span>
                </span>
              </span>
            </h1>
          </>
        )}

        <div className={`grid`}>
          <p className={textClasses}>{text}</p>
        </div>
      </div>
      <div className="grid">
        <div className={buttonContainerClasses}>
          {!richtext ? (
            <ButtonPrimary
              modifiers={['InlineFlex', 'IconHoverDown', 'IconRotate45']}
              icon="icon-arrow-right-white"
              onClick={() => {
                trackContactLead('Offer Intro');
                document.getElementById('footer-contact').scrollIntoView({
                  behavior: 'smooth',
                });
              }}>
              {t('Generic.contactUs')}
            </ButtonPrimary>
          ) : (
            <ButtonSecondary
              modifiers={['InlineFlex', 'IconRotate45', 'Share']}
              icon="icon-share-white"
              onClick={share.onClick}>
              {t('Generic.share')}
            </ButtonSecondary>
          )}
        </div>
      </div>
    </section>
  );
};

OfferIntro.propTypes = {
  titleTop: PropTypes.string,
  titleBottom: PropTypes.string,
  text: PropTypes.string,
};

OfferIntro.defaultProps = {
  titleTop: '',
  titleBottom: '',
  text: '',
};

export default OfferIntro;

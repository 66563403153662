import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';

import { AnimationsActiveContext } from '../../containers/BasePage';

import s from './VideoIcon.module.scss';

// For transparent videos to work in all browsers, video files in hevc (mp4) and vp9 (webm)
// are needed, and need to be supplied as follows in the srcs prop for this component:
//
// const srcs = [
//   {
//     src: '/video/video-with-alpha-hevc.mp4',
//     type: 'video/mp4; codecs="hvc1"',
//   },
//   {
//     src: '/video/video-with-alpha-vp9.webm',
//     type: 'video/webm',
//   },
// ];
//
// Given a hevc (or prores 4444) master in high resolution, the following steps
// can be used for conversion:
//
// 1. If master is in 1080p hevc, convert this to prores4444 first, using avconvert (or right click and "Encode video" in mac os):
// avconvert -s master.mov -o master-prores.mov -p PresetAppleProRes4444LPCM
//
// 2. Convert prores4444 master to prores4444 with correct down scaled resolution for use case, for example 350p:
// ffmpeg -i master-prores.mov -vcodec prores_ks -pix_fmt yuva444p10le -profile:v 4444 -vf scale="350:-1" master-prores-350p.mov
//
// 3. Convert prores 350p to final hevc 350p and vp9 350p:
// Use rotato converter: https://rotato.app/tools/converter
//
// 4. ....or do it manually since Rotato is buggy as hell:
// 4.a - prores downscaled to hevc:
// avconvert -prog --preset PresetHEVC1920x1080WithAlpha --source master-prores-350p.mov --output master-hevc-350p.mp4
// 4.b - prores downscaled to vp9:
// ffmpeg -i master-prores-350p.mov -stats_period 0.1 -c:v libvpx-vp9 -progress pipe:1 -nostdin master-vp9-350p.webm
//

const VideoIcon = ({
  className,
  src,
  srcs,
  active,
  loop,
  transitionDelay = 0,
  onVideoLoaded,
  animationsDisabledFreezeVideoTime,
  ignoreAnimationsDisabled,
}) => {
  const [videoNode, setVideoNode] = useState(null);
  const videoRefFn = useCallback((_videoNode) => {
    setVideoNode(_videoNode);
  }, []);
  const [isLoaded, setIsLoaded] = useState(false);

  const { animationsActive } = useContext(AnimationsActiveContext) || {};

  useEffect(() => {
    if (videoNode && !animationsActive && !ignoreAnimationsDisabled) {
      videoNode.pause();
      videoNode.currentTime = animationsDisabledFreezeVideoTime;
    } else if (videoNode) {
      videoNode.play();
    }
  }, [animationsActive]);

  useEffect(() => {
    if (active && videoNode && isLoaded) {
      if (transitionDelay > 0) {
        setTimeout(() => {
          if (onVideoLoaded) {
            // timeout is used to give the video a few ms to load/activate after download
            // before calling callback
            setTimeout(() => {
              onVideoLoaded();
            }, 100);
          }

          if (animationsActive || ignoreAnimationsDisabled) {
            videoNode.play();
          } else {
            videoNode.pause();
            videoNode.currentTime = animationsDisabledFreezeVideoTime;
          }
        }, transitionDelay);
      } else {
        if (onVideoLoaded) {
          // timeout is used to give the video a few ms to load/activate after download
          // before calling callback
          setTimeout(() => {
            onVideoLoaded();
          }, 100);
        }

        if (animationsActive || ignoreAnimationsDisabled) {
          videoNode.play();
        } else {
          videoNode.pause();
          videoNode.currentTime = animationsDisabledFreezeVideoTime;
        }
      }
    }
  }, [active, videoNode, isLoaded]);

  useEffect(() => {
    if (!videoNode) {
      return;
    }

    const activateVideo = () => {
      videoNode.play();
      if (!loop) {
        setTimeout(() => videoNode.pause(), 50);
      }

      document.removeEventListener('touchstart', activateVideo);
    };

    // ios
    document.addEventListener('touchstart', activateVideo);
    return () => {
      document.removeEventListener('touchstart', activateVideo);
    };
  }, [videoNode]);

  // handle video loaded event

  useEffect(() => {
    if (!videoNode) {
      return;
    }

    const videoHasLoaded = () => setIsLoaded(true);

    // if the video is in the cache of the browser,
    // the 'canplaythrough' event might have been triggered
    // before we registered the event handler.
    if (videoNode.readyState > 3) {
      videoHasLoaded();
      return;
    }

    videoNode.addEventListener('canplaythrough', videoHasLoaded);
    return () => {
      videoNode.removeEventListener('canplaythrough', videoHasLoaded);
    };
  }, [videoNode]);

  if (!src && !srcs.length) {
    return null;
  }

  return (
    <video
      className={`${s.Root} ${className}`}
      src={src}
      ref={videoRefFn}
      loop={loop}
      autoPlay={active}
      preload="auto"
      playsInline
      muted>
      {srcs.map((s) => (
        <source key={s.src} src={s.src} type={s.type} />
      ))}
      <track kind="subtitles" src="/video/no-audio.vtt" srcLang="en" />
    </video>
  );
};

VideoIcon.propTypes = {
  src: PropTypes.string,
  srcs: PropTypes.array,
  active: PropTypes.bool,
  loop: PropTypes.bool,
  transitionDelay: PropTypes.number,
  onVideoLoaded: PropTypes.func,
  animationsDisabledFreezeVideoTime: PropTypes.number,
  ignoreAnimationsDisabled: PropTypes.bool,
};

VideoIcon.defaultProps = {
  className: null,
  src: null,
  srcs: [],
  active: false,
  loop: false,
  transitionDelay: null,
  onVideoLoaded: null,
  animationsDisabledFreezeVideoTime: 0.5,
  ignoreAnimationsDisabled: false,
};

export default VideoIcon;

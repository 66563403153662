import React, { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import Link from 'next/link';
import Image from 'next/image';
import ButtonSecondary from '../ButtonSecondary';
import s from './OfferHero.module.scss';
import { trackContactLead } from '../../utils/dataLayer';
import classNames from 'classnames';

const OfferHero = ({ titleTop, titleBottom, text, tagline, links = [] }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const { t } = useTranslation();

  const titleClasses = classNames(s.Title, 'grid', { [s.Visible]: isVisible });
  const titleMobileClasses = classNames(s.TitleMobile, {
    [s.Visible]: isVisible,
  });
  const contentClasses = classNames(s.Content, 'grid', {
    [s.Visible]: isVisible,
  });
  const buttonsClasses = classNames(s.Buttons, 'grid', {
    [s.Visible]: isVisible,
  });
  const taglineClasses = classNames(s.Tagline, 'grid', {
    [s.Visible]: isVisible,
  });

  return (
    <section className={s.Root}>
      <h1 className={taglineClasses}>
        <span className={s.TaglineText}>{tagline}</span>
      </h1>
      <div className={titleMobileClasses}>
        <div className={titleClasses}>
          <div className={s.TitleContainer}>
            <span className={s.TitleTop}>{titleTop}</span>{' '}
            <span className={s.TitleBottom}>{titleBottom}</span>
          </div>
        </div>
      </div>
      <div className={s.TitleDesktop}>
        <div className={titleClasses}>
          <span className={s.TitleTop}>{titleTop}</span>
          <span className={s.TitleBottom}>{titleBottom}</span>
        </div>
      </div>
      <div className={contentClasses}>
        <p className={s.Text}>{text}</p>
      </div>
      <div className={buttonsClasses}>
        <div className={s.ButtonContainer}>
          <ButtonSecondary
            asLink
            href="#footer-contact"
            onClick={(e) => {
              trackContactLead('Offer Hero');
              e.preventDefault();
              document.getElementById('footer-contact').scrollIntoView({
                behavior: 'smooth',
              });
            }}
            modifiers={['InlineFlex', 'IconHoverDown', 'IconRotate45']}
            icon="icon-arrow-right-white">
            {t('Generic.contactUs')}
          </ButtonSecondary>
        </div>
        <ul className={s.Links}>
          {links.map(({ label, anchor }) => {
            return (
              <li key={anchor} className={s.ListItem}>
                <Link href={anchor} legacyBehavior>
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      const el = document.getElementById(
                        anchor.replace('#', '')
                      );
                      el.setAttribute('tabindex', 0);
                      el.focus();
                      el.style.outline = 0;
                      el.setAttribute('tabindex', -1);
                      el.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}
                    className={s.Link}>
                    {label}
                    <Image
                      width={20}
                      height={20}
                      src="/img/icon-arrow-right-white.svg"
                      alt=""
                      className={s.Arrow}
                    />
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};

OfferHero.propTypes = {};

OfferHero.defaultProps = {};

export default OfferHero;

import classNames from 'classnames';
import { string } from 'prop-types';
import React, { useContext, useRef } from 'react';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { useScrollDirection } from '../../hooks/useScrollDirection';
import { BackgroundContext, GradientContext } from '../../containers/BasePage';
import GradientBlurb from '../GradientBlurb';

import { motion, useScroll, useTransform } from 'framer-motion';

// import { useTranslation } from 'next-i18next';
import s from './TransitionTextBlock.module.scss';

const TransitionTextBlock = ({
  titleBottom,
  titleTop,
  noBackgroundTransition,
  anchorLink,
}) => {
  const containerRef = useRef(null);
  const gradient = useContext(GradientContext);
  const background = useContext(BackgroundContext);
  const ioConfigiration = [0, '-20% 0% -20% 0%', false];

  const { toggleWhiteBackground, whiteBackground } = background;
  const positionEnum = Object.freeze({
    below: 'BELOW',
    over: 'OVER',
    center: 'CENTER',
  });

  const [bottomRef, isBottomVisible, bottomPosition] = useIntersectionObserver(
    1,
    '50% 0% 0% 0%',
    false
  );
  const [ref, isVisible, position] = useIntersectionObserver(
    ...ioConfigiration
  );

  const [scrollDirection, scrollDirectionEnum] = useScrollDirection(ref);

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['-200vh', '-0vh'],
  });

  const topValue = useTransform(scrollYProgress, [0, 1], ['200%', '-8%']);
  const bottomValue = useTransform(scrollYProgress, [0, 1], ['-200%', '8%']);

  if (
    (isBottomVisible &&
      scrollDirection === scrollDirectionEnum.down &&
      !noBackgroundTransition) ||
    (bottomPosition === positionEnum.center && !noBackgroundTransition)
  ) {
    toggleWhiteBackground(true);
  } else if (
    (scrollDirection === scrollDirectionEnum.up &&
      bottomPosition === (positionEnum.below || positionEnum.center)) ||
    !!noBackgroundTransition
  ) {
    toggleWhiteBackground(false);
  }

  const classes = classNames(s.Root, { [s.Blank]: titleTop === 'BLANK' });

  const containerClasses = classNames(s.TitleContainer, 'grid', {});

  const topTitleClasses = classNames(s.TitleTop, s[gradient], {});

  const bottomTitleClasses = classNames(s.TitleBottom, s[gradient], {});

  return titleBottom && titleTop ? (
    <section
      className={classes}
      id={anchorLink ? anchorLink : 'Transition'}
      ref={ref}>
      <div className={containerClasses} ref={containerRef}>
        {titleTop !== 'BLANK' && (
          <motion.div
            className={topTitleClasses}
            style={{ x: topValue }}
            transformTemplate={({ x }) => `translate3d(${x}, 0, 0)`}>
            {titleTop}
          </motion.div>
        )}
        <motion.div
          ref={ref}
          className={bottomTitleClasses}
          style={{ x: bottomValue }}
          transformTemplate={({ x }) => `translate3d(${x}, 0, 0)`}>
          {titleBottom !== 'BLANK' && titleBottom}
        </motion.div>
        {!whiteBackground && (
          <span className={s.Blurb}>
            <GradientBlurb />
          </span>
        )}

        <div ref={bottomRef} className={s.MarginBottom}></div>
      </div>
    </section>
  ) : null;
};

TransitionTextBlock.propTypes = {
  titleBottom: string,
  titleTop: string,
};

TransitionTextBlock.defaultProps = {
  titleBottom: '',
  titleTop: '',
};

export default TransitionTextBlock;

import React, { useContext } from 'react';

// import i18n from '../../i18n';
// import PropTypes from 'prop-types';
import {
  BackgroundContext,
  AnimationsActiveContext,
} from '../../containers/BasePage';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import OfferHero from '../../components/OfferHero';
import OfferIntro from '../../components/OfferIntro';
import OfferSection from '../../components/OfferSection';
import TransitionTextBlock from '../../components/TransitionTextBlock';
import HeroBackground from '../../components/HeroBackground';
import StreamField from '../../components/StreamField';
import Shuffle from '../../components/Shuffle';
import FooterContact from '../../components/FooterContact';
import { basePageWrap } from '../BasePage';
import s from './WhatWeOfferPage.module.scss';
import classNames from 'classnames';

const WhatWeOfferPage = ({
  gradient,
  streamfield,
  footerContact = {},
  offerHero = {},
  offerIntro = {},
  offerSection = {},
  transitionText = {},
  shuffle = {},
}) => {
  const { animationsActive } = useContext(AnimationsActiveContext);
  const classes = classNames(s.Root, s[gradient], {
    [s.StopAnimations]: !animationsActive,
  });
  return (
    <div className={classes}>
      <ReduceMotionButton />
      <HeroBackground />
      <OfferHero {...offerHero} />
      <OfferIntro {...offerIntro} />
      <div className={s.Overflow}>
        <OfferSection {...offerSection} />
        <StreamField blocks={streamfield?.blocks} />
        {!!shuffle?.item?.title && <Shuffle {...shuffle} />}
        {!!transitionText?.titleTop && (
          <TransitionTextBlock {...transitionText} />
        )}
        <StreamField blocks={streamfield?.blocksBelowTransition} />
        <FooterContact {...footerContact} />
      </div>
    </div>
  );
};

const ReduceMotionButton = () => {
  const { t } = useTranslation();

  const { whiteBackground } = useContext(BackgroundContext);
  const { animationsActive, toggleAnimationsActive } = useContext(
    AnimationsActiveContext
  );

  const wrapperCls = classNames(s.ReduceMotionButtonWrapper, {
    [s.BlackText]: whiteBackground,
  });

  const iconSrc = `/img/${
    animationsActive
      ? whiteBackground
        ? 'icon-pause-black'
        : 'icon-pause-white'
      : whiteBackground
      ? 'icon-play-black'
      : 'icon-play-white'
  }.svg`;

  return (
    <div className={wrapperCls}>
      <button className={s.ReduceMotionButton} onClick={toggleAnimationsActive}>
        <Image
          className={s.ReduceMotionIcon}
          width={20}
          height={20}
          src={iconSrc}
          alt={animationsActive ? t('Generic.pause') : t('Generic.play')}
        />
        {animationsActive
          ? t('Insights.reduceMotion')
          : t('Insights.enableRegularMotion')}
      </button>
    </div>
  );
};

export default basePageWrap(WhatWeOfferPage);

import react, { useState, useRef } from 'react';
import useIsomorphicLayoutEffect from './useIsoMorphicLayoutEffect';
const THRESHOLD = 0;

const useScrollDirection = () => {
  const scrollDirectionEnum = Object.freeze({
    Up: 0,
    Down: 1,
    // up: 0,
    // down: 1,
  });

  const [scrollDirection, setScrollDirection] = useState(null);

  const blocking = useRef(false);
  const prevScrollY = useRef(0);

  useIsomorphicLayoutEffect(() => {
    prevScrollY.current = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection =
          scrollY > prevScrollY.current
            ? scrollDirectionEnum.up
            : scrollDirectionEnum.down;

        setScrollDirection(newScrollDirection);

        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollDirection]);

  return [scrollDirection, scrollDirectionEnum];
};

export default useScrollDirection;
export { useScrollDirection };

import React, { useState, useContext } from 'react';

import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import ButtonPrimary from '../ButtonPrimary';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { GradientContext, BackgroundContext } from '../../containers/BasePage';
import classNames from 'classnames';
import { trackShuffle, trackReadMore } from '../../utils/dataLayer';
import s from './Shuffle.module.scss';

const Shuffle = ({
  titleTop,
  titleBottom,
  text,
  color = 'black',
  item = {},
  href = {},
}) => {
  const [ref, isVisible] = useIntersectionObserver();
  const [shuffleItem, setShuffleItem] = useState(item);
  const [isFetching, setIsFetching] = useState(false);
  const gradient = useContext(GradientContext);
  const background = useContext(BackgroundContext) || false;

  const { whiteBackground } = background;
  const getNewItem = async () => {
    setIsFetching(true);
    trackShuffle();

    const resp = await fetch(
      `${process.env.NEXT_PUBLIC_WAGTAIL_API_URL}/v1/get_shuffle_item/?not=${shuffleItem?.index}`
    );
    const data = await resp.json();

    setTimeout(() => {
      setShuffleItem(data.item);
      setIsFetching(false);
    }, 300);
  };

  const { t } = useTranslation();

  const rootClasses = classNames(s.Root, s[gradient], {
    [s.White]: color !== 'black' || whiteBackground,
  });

  const titleClasses = classNames(s.TitleContainer, 'grid', {
    [s.Visible]: isVisible,
    [s.White]: color !== 'black',
  });

  const textClasses = classNames(s.TextContainer, 'grid', {
    [s.Visible]: isVisible,
    [s.White]: color !== 'black',
  });

  const buttonDesktopClasses = classNames(s.ButtonDesktop, 'grid', {
    [s.Visible]: isVisible,
  });

  const buttonMobileClasses = classNames(s.ButtonMobile, 'grid', {
    [s.Visible]: isVisible,
  });

  const itemClasses = classNames(s.Item, {
    [s.Visible]: isVisible,
    [s.White]: color !== 'black',
    [s.Fetching]: isFetching,
  });

  const isExternal =
    shuffleItem?.href?.href?.indexOf('http://') !== -1 ||
    shuffleItem?.href?.href?.indexOf('https://') !== -1;

  return (
    <section className={rootClasses} ref={ref}>
      <div className={titleClasses}>
        <span className={s.TitleTop}>{titleTop}</span>
        <span className={s.TitleBottom}>{titleBottom}</span>
      </div>
      <div className={textClasses}>
        <p className={s.Text}>{text}</p>
      </div>
      <div className={`${s.ItemWrapper} grid`}>
        <div className={itemClasses}>
          <div className={s.ImageContainer}>
            <Image
              className={s.Image}
              src={shuffleItem?.image?.url}
              alt={shuffleItem?.image?.alt || ''}
              fill
            />
            <span className={s.ImageCred}>
              {t('Image.photo', { credit: shuffleItem?.image?.credit })}
            </span>
          </div>
          <div
            className={`${s.Content} ${
              color !== 'black' ? 'white-glass' : 'black-glass'
            }`}>
            <span className={s.Index}>
              {t('Shuffle.index', { index: shuffleItem?.index })}
            </span>
            <h5 className={s.CardTitle}>{shuffleItem?.title}</h5>
            <p className={s.CardText}>{shuffleItem?.text}</p>
            {!!shuffleItem?.href && (
              <Link href={shuffleItem?.href} legacyBehavior>
                <a
                  onClick={() => {
                    trackReadMore(shuffleItem?.title, 'Shuffle');
                  }}
                  className={s.Link}>
                  <Image
                    width={isExternal ? 18 : 20}
                    height={isExternal ? 18 : 20}
                    src={
                      isExternal
                        ? '/img/icon-external-white.svg'
                        : '/img/icon-arrow-right-white.svg'
                    }
                    alt=""
                    className={!isExternal ? s.Arrow : ''}
                  />
                  {t('Generic.readMore')}
                  <span className="sr-only">{shuffleItem?.title}</span>
                </a>
              </Link>
            )}
          </div>
          <div className={buttonDesktopClasses}>
            <ButtonPrimary
              icon={'icon-shuffle-white'}
              onClick={getNewItem}
              modifiers={['ShuffleDesktop']}>
              {t('Shuffle.newGuide', s[gradient])}
            </ButtonPrimary>
          </div>
        </div>
      </div>
      <div className={buttonMobileClasses}>
        <ButtonPrimary
          href={href.href}
          icon={'icon-shuffle-white'}
          onClick={getNewItem}
          modifiers={['ShuffleMobile', s[gradient]]}>
          {!!href.text ? href.text : t('Shuffle.newGuide')}
        </ButtonPrimary>
      </div>
    </section>
  );
};

Shuffle.propTypes = {};

Shuffle.defaultProps = {};

export default Shuffle;
